import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LoadingSpinner } from 'src/components/Common';
import { LIVE_PLAYPAGE_STATUS } from 'src/constants';

const PlayerNotice = ({ status, erroCode = null, liveName, liveThumbnail }) => {
  let title = '';
  let message = '';

  switch (status) {
    case LIVE_PLAYPAGE_STATUS.LOADING:
      title = '読み込み中..';
      break;

    case LIVE_PLAYPAGE_STATUS.NOT_FOUND:
      title = 'お探しのページが見つかりません';
      message =
        '入力したアドレスが間違っているか、ページが移動した可能性があります。';
      break;

    case LIVE_PLAYPAGE_STATUS.LIVE_BEFORE:
      title = '現在は視聴できません。';
      message = 'LIVE配信開始まで今しばらくお待ち下さい。';
      break;

    case LIVE_PLAYPAGE_STATUS.LIVE_FINISHED:
      title = '現在は視聴できません。';
      message = 'LIVE配信は終了しました。';
      break;

    case LIVE_PLAYPAGE_STATUS.ERROR:
    default:
      title = '予期せぬエラーが発生しました';
      message =
        'サーバへのアクセスが混み合っているか、メンテナンス中の可能性があります。しばらく時間をおいてから、もう一度アクセスしてください。';
      break;
  }

  return (
    <>
      <Container>
        <div>
          {liveName && <LiveTitle>{liveName}</LiveTitle>}
          <Title>{title}</Title>
          <Message>
            {message}
            {erroCode && <div>{`(${erroCode})`}</div>}
          </Message>
        </div>
      </Container>
      {status === LIVE_PLAYPAGE_STATUS.LOADING && <LoadingSpinner />}
      {liveThumbnail && (
        <>
          <Background
            style={{
              backgroundImage: `url("//${liveThumbnail}?output-format=jpg&output-quality=80")`,
            }}
          />
          <Filter />
        </>
      )}
    </>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;

  & > div {
    text-align: center;
    width: 100%;
  }
`;

const LiveTitle = styled.div`
  padding: 0.5rem 0;
  font-size: 1rem;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.text.primary};
  font-size: 1.5rem;
`;

const Message = styled.div`
  margin-top: 0.5rem;
  font-size: 1rem;
`;

const Background = styled.div`
  filter: blur(6px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Filter = styled.div`
  background-color: ${({ theme }) => theme.background.standard};
  opacity: 0.8;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

PlayerNotice.propTypes = {
  status: PropTypes.number.isRequired,
  erroCode: PropTypes.string,
  liveName: PropTypes.string,
  liveThumbnail: PropTypes.string,
};

export default PlayerNotice;
