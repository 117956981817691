import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from 'src/styles/theme';
import GlobalStyle from 'src/styles/globalStyle';

const withLayout = (WrappedComponent) => {
  const WithLayout = (props) => {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <WrappedComponent {...props} />
      </ThemeProvider>
    );
  };

  return WithLayout;
};

export default withLayout;
