import React, { createContext, useState, useRef, useEffect } from 'react';
import Hls from 'hls.js';

const EnableHlsAesContext = createContext();

const withEnableHlsAes = (WrappedComponent) => {
  const WithEnableHlsAes = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const videoRef = useRef();

    useEffect(() => {
      const checkHlsSupport = () => {
        if (Hls.isSupported()) {
          return true;
        }

        if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
          return true;
        }

        return false;
      };

      setTimeout(() => {
        setIsEnabled(checkHlsSupport());
        setIsChecked(true);
      }, 1500);
    }, [videoRef]);

    return (
      <>
        {!isChecked && (
          <video
            ref={videoRef}
            style={{
              width: '1px',
              height: '1px',
              position: 'fixed',
              zIndex: '-1',
            }}
          />
        )}
        <EnableHlsAesContext.Provider value={{ isChecked, isEnabled }}>
          <WrappedComponent {...props} />
        </EnableHlsAesContext.Provider>
      </>
    );
  };

  return WithEnableHlsAes;
};

export { withEnableHlsAes };
export default EnableHlsAesContext;
