import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import 'url-search-params-polyfill';

import withLayout from 'src/components/Layout';
import Splash from 'src/components/Splash';
import UnSupported from 'src/components/UnSupported';
import LivePlay from 'src/components/LivePlay';
import { withUuid } from 'src/hocs/withUuid';
import EnableHlsAesContext, {
  withEnableHlsAes,
} from 'src/hocs/withEnableHlsAes';

const App = ({ queryString }) => {
  const { isChecked, isEnabled } = useContext(EnableHlsAesContext);

  if (!isChecked) {
    return <Splash />;
  }

  if (!isEnabled) {
    return <UnSupported />;
  }

  return (
    <LivePlay
      env={queryString.env === 'it' ? 'IT' : 'PROD'}
      liveCode={queryString.lc}
    />
  );
};

App.propTypes = {
  queryString: PropTypes.object,
};

export default withLayout(withUuid(withEnableHlsAes(App)));
