import 'date-utils';
import React from 'react';
import PropTypes from 'prop-types';
import {
  API_ENDPOINT as ENDPOINT,
  LIVE_RESULT_STATUS,
  LIVE_PLAYPAGE_STATUS,
} from 'src/constants';

import useFetchApi from 'src/hooks/useFetchApi';
import PlayerNotice from './PlayerNotice';
import VideoPlayer from './VideoPlayer';

const LivePlayPage = ({ env, liveCode }) => {
  const detailUrl = `${ENDPOINT[env].URL}${ENDPOINT.API_PATH_LIVE_DETAIL}/${liveCode}`;
  const getPlaylistUrl = `${ENDPOINT[env].URL}${ENDPOINT.API_PATH_LIVE_PLAYLIST_URL}/${liveCode}`;

  const {
    response: detailApiRes,
    error: detailApiErr,
    loading: detailApiLoading,
  } = useFetchApi(detailUrl);

  const {
    response: playlistUrlApiRes,
    error: playlistUrlApiErr,
    loading: playlistUrlApiLoading,
  } = useFetchApi(getPlaylistUrl);

  if (detailApiLoading || playlistUrlApiLoading) {
    return <PlayerNotice status={LIVE_PLAYPAGE_STATUS.LOADING} />;
  }

  if (detailApiErr || playlistUrlApiErr) {
    return <PlayerNotice status={LIVE_PLAYPAGE_STATUS.ERROR} />;
  }

  const liveName = detailApiRes.data.live_name
    ? detailApiRes.data.live_name
    : '';
  const liveThumbnail = detailApiRes.data.live_notices
    ? detailApiRes.data.live_notices[0].thumbnail.standard
    : '';

  switch (parseInt(playlistUrlApiRes.data.result_status, 10)) {
    case LIVE_RESULT_STATUS.PLAYING:
      break;

    case LIVE_RESULT_STATUS.PREPARATION:
      return (
        <PlayerNotice
          status={LIVE_PLAYPAGE_STATUS.LIVE_BEFORE}
          liveName={liveName}
          liveThumbnail={liveThumbnail}
        />
      );

    case LIVE_RESULT_STATUS.FINISHED:
      return (
        <PlayerNotice
          status={LIVE_PLAYPAGE_STATUS.LIVE_FINISHED}
          liveName={liveName}
          liveThumbnail={liveThumbnail}
        />
      );

    default:
      return (
        <PlayerNotice
          status={LIVE_PLAYPAGE_STATUS.ERROR}
          erroCode={playlistUrlApiRes.data.result_status}
          liveName={liveName}
          liveThumbnail={liveThumbnail}
        />
      );
  }

  return (
    <VideoPlayer
      env={env}
      liveData={detailApiRes.data}
      playlistData={playlistUrlApiRes.data}
    />
  );
};

LivePlayPage.propTypes = {
  env: PropTypes.string,
  liveCode: PropTypes.string,
};

export default LivePlayPage;
