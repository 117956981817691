export const MOVIE_PROFILE_TYPE = 'hls-aes';

export const DEVICE_CODE = {
  PC: 700,
  MAC_SAFARI: 720,
  SP_ANDROID: 920,
  TB_ANDROID: 940,
  SP_IOS: 1020,
  TB_IOS: 1030,
};

export const API_ENDPOINT = {
  API_PATH_LIVE_DETAIL: '/1/lcms/live',
  API_PATH_LIVE_PLAYLIST_URL: '/2/lcms/live_playlisturl',

  IT: {
    URL: 'https://d2cpe0nlpjube.cloudfront.net/production/it',
    HLS_SET_COOKIE_ENDPOINT:
      'https://it-bl.unext.jp/unextliveaes128lic/setplaytoken',
  },
  PROD: {
    URL: 'https://d2cpe0nlpjube.cloudfront.net/production/prod',
    HLS_SET_COOKIE_ENDPOINT:
      'https://hlslicense.unext.jp/unextliveaes128lic/setplaytoken',
  },
};

export const LIVE_PLAYPAGE_STATUS = {
  LOADING: 0,
  ERROR: 1,
  NOT_FOUND: 2,
  LIVE_BEFORE: 3,
  LIVE_FINISHED: 4,
};

export const LIVE_RESULT_STATUS = {
  PLAYING: 200,
  // 1104	ライブの準備ができていないエラー（配信開始前）
  PREPARATION: 1104,
  // 1103	ライブが配信終了したエラー
  FINISHED: 1103,
};

export const IGNORE_BROWSER_LIST = [
  { title: 'iOS 9 以上', list: ['Safari', 'Chrome'] },
  {
    title: 'Android 4.1 以上',
    list: ['Chrome バージョン34以上', 'Firefox バージョン41以上'],
  },
  {
    title: 'Windows 7以上',
    list: ['Chrome バージョン34以上', 'Firefox バージョン42以上'],
  },
  {
    title: 'Windows 8.1以上',
    list: [
      'Chrome バージョン34以上',
      'Firefox バージョン42以上',
      'IE 11以上',
      'Edge',
    ],
  },
  {
    title: 'macOS 10.9以上',
    list: ['Chrome バージョン34以上', 'Firefox バージョン42以上', 'Safari'],
  },
];

export const TD_CONFIG = {
  TABLE_NAME: 'live_play_log',
  IT: {
    host: 'in.treasuredata.com',
    database: 'dev_unext_log_db',
    writeKey: '5561/0d09459581025432a90b9bc3bd80dc316795e435',
  },
  PROD: {
    host: 'in.treasuredata.com',
    database: 'unext_log_db',
    writeKey: '5561/009da378c9fb9a70e621669e98a06e16d25041ef',
  },
};
