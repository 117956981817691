import React from 'react';
import styled from 'styled-components';
import { IGNORE_BROWSER_LIST } from 'src/constants';

const UnSupported = () => (
  <Container>
    <Title>ご案内</Title>
    <Text>
      ネットワークが不安定、もしくはご利用のブラウザがサポート対象外です。
      <br />
      環境を確認した上で、下記のブラウザをご利用ください。
    </Text>
    <SupportedList>
      {IGNORE_BROWSER_LIST.map(({ title, list }) => (
        <React.Fragment key={title}>
          <dt>{title}</dt>
          {list.map((data, index) => (
            <dd key={index}>{data}</dd>
          ))}
        </React.Fragment>
      ))}
    </SupportedList>
  </Container>
);

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
  padding: 4rem 0;
  width: calc(100% - 3rem);
`;

const Title = styled.div`
  color: ${({ theme }) => theme.text.primary};
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.4;
`;

const Text = styled.div`
  margin-top: 0.5rem;
  font-size: 1rem;
`;

const SupportedList = styled.dl`
  padding: 1rem 0;

  & > dt {
    color: ${({ theme }) => theme.text.primary};
    margin-top: 0.5rem;
    font-weight: bold;
    line-height: 1.4;
  }

  & > dd {
    padding-left: 1rem;
  }
`;

export default UnSupported;
