import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';
import styled from 'styled-components';

import UuidContext from 'src/hocs/withUuid';
import useLivePlayLog from 'src/hooks/useLivePlayLog';
import { API_ENDPOINT, MOVIE_PROFILE_TYPE } from 'src/constants';
import { LoadingSpinner } from 'src/components/Common';
import PlayBtn from './PlayBtn';

const STATUS = {
  INIT: 'init',
  PLAY: 'play',
  PLAYING: 'playing',
  WAITING: 'waiting',
  PAUSE: 'pause',
  ERROR: 'error',
};

const choiceCDN = (playlistData) => {
  if (playlistData.endpoint_version === 1) {
    return playlistData.endpoint_urls[MOVIE_PROFILE_TYPE];
  }

  if (playlistData.endpoint_version === 2) {
    const cdns = playlistData.endpoint_urls[0].playables[MOVIE_PROFILE_TYPE];
    const r = Math.random() * cdns.reduce((w, cdn) => cdn.weight + w, 0);
    let idx = 0;
    let threshold = 0;
    cdns.forEach((cdn) => {
      threshold = threshold + cdn.weight;
      if (r > threshold) {
        idx = idx + 1;
      }
    });

    return cdns[idx];
  }

  // TODO 1, 2以外がきたら死ぬ
};

const VideoPlayer = ({ env, liveData, playlistData }) => {
  const { uuid } = useContext(UuidContext);
  const videoRef = useRef();
  const [playerStatus, setPlayerStatus] = useState(STATUS.INIT);

  useLivePlayLog({
    env,
    uuid,
    liveCode: liveData.live_code,
    playTokenHash: playlistData.play_token_hash,
  });

  const endpointUrlData = choiceCDN(playlistData);
  const playlistUrl = endpointUrlData.playlist_url.replace(/http:/g, 'https:');
  const playToken = playlistData.play_token;

  const handleClickPlay = () => {
    videoRef.current.play();
    setPlayerStatus(STATUS.PLAY);
  };

  const handleWaiting = () => {
    setPlayerStatus(STATUS.WAITING);
  };

  const handlePlaying = () => {
    setPlayerStatus(STATUS.PLAYING);
  };

  const handlePause = () => {
    setPlayerStatus(STATUS.PAUSE);
  };

  useEffect(() => {
    const vRef = videoRef.current;
    const setUpHlsjs = async () => {
      if (Hls.isSupported()) {
        const hls = new Hls({
          loader: function (config) {
            let loader = new Hls.DefaultConfig.loader(config);
            this.abort = () => loader.abort();
            this.destroy = () => loader.destroy();
            this.load = (context, config, callbacks) => {
              if (
                context &&
                context.frag &&
                context.frag.levelkey &&
                context.url === context.frag.levelkey.reluri
              ) {
                const keyUrl = new URL(context.url);
                keyUrl.searchParams.append('play_token', playToken);
                context.url = keyUrl.href;
              }
              loader.load(context, config, callbacks);
            };
          },
        });

        hls.loadSource(playlistUrl);
        hls.attachMedia(vRef);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          vRef.play();
        });
        return;
      }

      if (vRef.canPlayType('application/vnd.apple.mpegurl')) {
        await fetch(
          `${API_ENDPOINT[env].HLS_SET_COOKIE_ENDPOINT}?play_token=${playToken}`,
          { method: 'GET', mode: 'cors', credentials: 'include' },
        ).then((res) => console.log(res));

        vRef.src = playlistUrl;
        return;
      }
    };

    setUpHlsjs();
    vRef.addEventListener('waiting', handleWaiting, false);
    vRef.addEventListener('playing', handlePlaying, false);
    vRef.addEventListener('pause', handlePause, false);

    return () => {
      vRef.removeEventListener('waiting', handleWaiting, false);
      vRef.removeEventListener('playing', handlePlaying, false);
      vRef.removeEventListener('pause', handlePause, false);
    };
  }, [env, playToken, playlistUrl]);

  return (
    <Container>
      <Video
        poster={`//${liveData.live_notices[0].thumbnail.standard}?output-format=jpg&output-quality=80`}
        controls={false}
        autoPlay={true}
        ref={videoRef}
      />
      {playerStatus === STATUS.INIT && <PlayBtn onClick={handleClickPlay} />}
      {(playerStatus === STATUS.INIT || playerStatus === STATUS.WAITING) && (
        <LoadingSpinner />
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.background.standard};
  height: 100%;
  width: 100%;
`;

const Video = styled.video`
  height: 100%;
  width: 100%;
`;

VideoPlayer.propTypes = {
  env: PropTypes.string.isRequired,
  liveData: PropTypes.object.isRequired,
  playlistData: PropTypes.object.isRequired,
};

export default VideoPlayer;
