import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

const COOKIE_NAME = '_pubv';

const UuidContext = createContext();

const withUuid = (WrappedComponent) => {
  const WithUuid = (props) => {
    const [uuid, setUuid] = useState();

    const setCookie = () => {
      const cookies = new Cookies();
      const hasCookie = cookies.get(COOKIE_NAME);
      const userId = hasCookie ? cookies.get(COOKIE_NAME) : uuidv4();
      setUuid(userId);

      if (hasCookie) {
        return;
      }

      const expire = new Date();
      const cookieDomain =
        window.location.hostname === 'localhost'
          ? 'localhost'
          : `.${window.location.hostname.split('.').slice(-2).join('.')}`;

      expire.setTime(expire.getTime() + 1000 * 3600 * 24 * 30);
      cookies.set(COOKIE_NAME, userId, {
        domain: cookieDomain,
        path: '/',
        expires: new Date(expire.toUTCString()),
      });
    };

    useEffect(() => {
      setCookie();
    }, []);

    return (
      <UuidContext.Provider value={{ uuid }}>
        <WrappedComponent {...props} />
      </UuidContext.Provider>
    );
  };

  return WithUuid;
};

export { withUuid };
export default UuidContext;
