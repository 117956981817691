import React from 'react';
import styled from 'styled-components';
import logo from 'src/assets/logo_white.svg';

const Splash = () => (
  <Container>
    <img src={logo} alt="U-NEXT" />
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;

  & > img {
    display: block;
    max-width: 560px;
    width: calc(100% - 3rem);
    margin: 0 auto;
  }
`;

export default Splash;
