import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'src/containers/App';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import queryString from 'query-string';

ReactDOM.render(
  <Router>
    <Route
      render={(props) => {
        return <App queryString={queryString.parse(props.location.search)} />;
      }}
    />
  </Router>,
  document.getElementById('root'),
);
