const theme = {
  color: {
    primary: '#0080de',
    secondary: '#f2a100',
  },
  background: {
    standard: 'rgb(1, 10, 23)',
  },
  text: {
    primary: 'rgba(255, 255, 255, 1)',
    standard: 'rgba(255, 255, 255, .8)',
  },
};

export default theme;
