import React from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

const LoadingSpinner = () => (
  <Container>
    <ClipLoader size={35} color={'#fff'} />
  </Container>
);

const Container = styled.div`
  content: '';
  display: block;
  position: fixed;
  z-index: 200;
  left: 2rem;
  bottom: 1rem;
  height: 3rem;
  width: 3rem;
`;

export default LoadingSpinner;
