import 'date-utils';
import { useEffect } from 'react';
import UAParser from 'ua-parser-js';
import Treasure from 'td-js-sdk';

import { TD_CONFIG, MOVIE_PROFILE_TYPE, DEVICE_CODE } from 'src/constants';

const ACTION_START = 'start';
const ACTION_STOP = 'stop';

const useLivePlayLog = ({ env, uuid, playTokenHash, liveCode }) => {
  useEffect(() => {
    const sendObject = {
      service_platform: 'unext',
      user_platform_id: '', // No data (userInfo)
      user_multi_account_id: '', // No data (userInfo)
      live_public_code: liveCode,
      playback_action_time: new Date().toFormat('YYYY-MM-DD HH24:MI:SS'),
      playback_action: '', // START or STOP
      os: getOSName(),
      os_version: getOSVersion(),
      app_version: '', // No data (only mobile app)
      browser_type: getBrowserType(),
      browser_version: getBrowserVersion(),
      other_function_id: '', // No data (userInfo)
      user_seq: ` ${Math.floor(Date.now() / 1000)}`,
      device_code: getDeviceCode(),
      device_name: getDeviceName(),
      screen_type: getScreenType(),
      site_code: '116',
      super_user_flg: '0',
      user_agent: window.navigator.userAgent,
      server_upload_time: '', // No data (only mobile app for download play)
      token_hash: playTokenHash,
      event_id: '', // No data (only mobile app)
      uuid,
      player: 'hls.js',
      movie_profile_type: MOVIE_PROFILE_TYPE,
      drm_type: '',
      td_ip: '', // No data (unknown)
    };

    const sentTdStart = () => {
      const td = new Treasure(TD_CONFIG[env]);
      const data = {
        ...sendObject,
        ...{ playback_action: ACTION_START },
      };
      td.addRecord(
        TD_CONFIG.TABLE_NAME,
        data,
        () => {},
        () => {},
      );
    };

    const sentTdStop = () => {
      const td = new Treasure(TD_CONFIG[env]);
      const data = {
        ...sendObject,
        ...{ playback_action: ACTION_STOP },
      };
      td.addRecord(
        TD_CONFIG.TABLE_NAME,
        data,
        () => {},
        () => {},
      );
    };

    sentTdStart();
    window.addEventListener('beforeunload', sentTdStop);

    return () => {
      window.removeEventListener('beforeunload', sentTdStop);
    };
  }, [env, playTokenHash, liveCode, uuid]);

  return {};
};

const getBrowserType = () => {
  const { browser } = new UAParser().getResult();
  return browser.name;
};

const getBrowserVersion = () => {
  const { browser } = new UAParser().getResult();
  return browser.version;
};

const getOSName = () => {
  const { os } = new UAParser().getResult();
  return os.name;
};

const getOSVersion = () => {
  const { os } = new UAParser().getResult();
  return os.version;
};

const getDeviceCode = () => {
  const { browser, os, device } = new UAParser().getResult();
  if (os.name === 'Android' && device.type !== 'tablet') {
    return DEVICE_CODE.SP_ANDROID;
  }

  if (os.name === 'Android' && device.type === 'tablet') {
    return DEVICE_CODE.TB_ANDROID;
  }

  if (device.model === 'iPhone' || device.model === 'iPod') {
    return DEVICE_CODE.SP_IOS;
  }

  if (device.model === 'iPad') {
    return DEVICE_CODE.TB_IOS;
  }

  if (os.name === 'Mac OS' && browser.name === 'Safari') {
    return DEVICE_CODE.MAC_SAFARI;
  }

  return DEVICE_CODE.PC;
};

const getDeviceName = () => {
  const { os, device } = new UAParser().getResult();
  if (os.name === 'Android') {
    return os.name;
  }

  if (
    device.model === 'iPad' ||
    device.model === 'iPhone' ||
    device.model === 'iPod'
  ) {
    return device.model;
  }

  return os.name;
};

const getScreenType = () => {
  const { device } = new UAParser().getResult();
  switch (device.type) {
    case 'mobile':
      return 'Phone';
    case 'tablet':
      return 'Tablet';
    default:
      return 'PC';
  }
};

export default useLivePlayLog;
