import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchApi = (url) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoadingState] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios({
          url,
          method: 'get',
        });
        const json = await res.data;
        setResponse(json);
      } catch (error) {
        setError(error);
      } finally {
        setLoadingState(false);
      }
    })();
  }, [url]);

  return { response, error, loading };
};

export default useFetchApi;
