import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as PlayIcon } from 'src/assets/playBtn.svg';

const PlayBtn = ({ onClick }) => (
  <Container onClick={onClick}>
    <Icon>
      <PlayIcon />
    </Icon>
  </Container>
);

const Container = styled.div`
  background-color: ${({ theme }) => theme.background.standard};
  opacity: 0.6;
  content: '';
  display: block;
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const Icon = styled.div`
  content: '';
  display: block;
  position: fixed;
  z-index: 201;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: 8rem;
  width: 8rem;

  & > svg {
    height: 100%;
    width: 100%;
  }
`;

PlayBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default PlayBtn;
